"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOrderDetailProductToproduct = exports.IS_PRODUCIBLE_TAG = void 0;
var discount_types_1 = require("../../../types/discount.types");
var compute_tax_rate_1 = require("../../tax-rate/compute-tax-rate");
var initialize_price_values_1 = require("../initialize-price-values");
exports.IS_PRODUCIBLE_TAG = 'isproducible';
var mapOrderDetailProductToproduct = function (product) {
    var inclTax = product.amount;
    var exclTax = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(inclTax, product.taxRate);
    var unitInclTax = product.amount.dividedBy(product.quantity);
    var unitExclTax = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(unitInclTax, product.taxRate);
    var tags = product.tags || [];
    if (product.applyScopeId) {
        tags.push(product.applyScopeId);
    }
    if (product.isProducible) {
        tags.push(exports.IS_PRODUCIBLE_TAG);
    }
    return {
        label: product.label,
        id: product.id,
        productCartId: product.productCartId,
        discountableType: discount_types_1.DISCOUNTABLE_TYPE.PRODUCT,
        totalPriceNotRounded: (0, initialize_price_values_1.initPricesNotRounded)(inclTax, exclTax),
        totalPriceRounded: (0, initialize_price_values_1.initPricesRounded)(),
        unitPriceNotRounded: (0, initialize_price_values_1.initPricesNotRounded)(unitInclTax, unitExclTax),
        unitPriceRounded: (0, initialize_price_values_1.initPricesRounded)(),
        taxRate: product.taxRate,
        depth: product.depth,
        quantity: product.quantity,
        tags: tags,
        parentTags: [],
        isEligibleLuncheon: product.isEligibleLuncheon ? product.isEligibleLuncheon : false,
        billingCode: product.billingCode,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        additionalData: product.additionalData,
        sku: product.sku,
        purchasePrice: product.purchasePrice,
        comment: product.comment,
    };
};
exports.mapOrderDetailProductToproduct = mapOrderDetailProductToproduct;
